<template>
  <v-card
      hover
      width="370">
    <v-card-title class="text-break " style="font-size: 16px">
      <v-row justify="space-between" class="ml-1">
        <span>{{ proceso.codigo_proceso }}</span>
        <v-chip :color="proceso.estado_actual_color" class="white--text">{{ proceso.estado_actual }}</v-chip>
      </v-row>
    </v-card-title>
    <v-card-subtitle>
    </v-card-subtitle>
    <v-divider/>
    <v-card-text style="height: 200px; overflow-y: auto;">
      <v-row no-gutters>
        <v-col cols="1">
          <v-tooltip top color="tableAccent">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  color="tableAccent"
                  v-bind="attrs"
                  v-on="on">
                mdi-office-building-outline
              </v-icon>
            </template>
            <span>Nombre de la institución</span>
          </v-tooltip>
        </v-col>
        <v-col cols="11">
          <p class="text-uppercase"> {{ proceso.institucion }}</p>
        </v-col>
        <v-col cols="1">
         <v-tooltip top color="tableAccent">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  color="tableAccent"
                  v-bind="attrs"
                  v-on="on">
                mdi-cart-percent
              </v-icon>
            </template>
            <span>Forma de contratación</span>
          </v-tooltip>
        </v-col>
        <v-col cols="11">
          <p class="text-uppercase">{{ proceso?.forma_contratacion }}</p>
        </v-col>
        <v-col cols="1">
          <v-tooltip top color="tableAccent">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  color="tableAccent"
                  v-bind="attrs"
                  v-on="on">
                mdi-cog-outline
              </v-icon>
            </template>
            <span>Nombre del proceso</span>
          </v-tooltip>
        </v-col>
        <v-col cols="11">
          <p class="text-uppercase">{{ proceso.nombre_proceso }}</p>
        </v-col>
        <v-col cols="1">
          <v-tooltip top color="tableAccent">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  color="tableAccent"
                  v-bind="attrs"
                  v-on="on">
                mdi-calendar-month
              </v-icon>
            </template>
            <span>Periodo de inscripción</span>
          </v-tooltip>
        </v-col>
        <v-col cols="11">
          <p class="text-uppercase">
            {{ moment(proceso.fecha_inicio_inscripcion).format('DD-MM-YYYY') }} - {{
              moment(proceso.fecha_fin_inscripcion).format('DD-MM-YYYY')
            }}
          </p>
        </v-col>
      </v-row>
<!--      <v-divider/>-->
<!--      <v-row class="mt-3 px-3">-->
<!--        <v-btn-->
<!--            @click="show(proceso)"-->
<!--            outlined-->
<!--            block>-->
<!--          {{ proceso?.actividades?.length ? 'Ver activiades economicas': 'No hay actividades económicas'}}-->
<!--        </v-btn>-->
<!--      </v-row>-->
    </v-card-text>
    <v-divider class="my-2"/>
    <v-card-actions>
      <v-btn
          color="tableAccent"
          class="white--text"
          @click="$emit('update:redirect', proceso)">
        Ir al proceso
      </v-btn>
      <!--              <v-chip :color="proceso.estado_actual_color" class="white--text">{{ proceso.estado_actual}}</v-chip>-->
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: 'tarjetasProcesos',
  props: {
    irA: {},
    proceso: {
      type: Object,
      default: null
    }
  },
  methods:{
    show(proceso){
      if(proceso?.actividades?.length)
        this.$emit('mostrarActividades', proceso.actividades)
      else this.$emit('elementoVacio')
    }
  }
}
</script>
<style lang="scss" scoped>

p {
  font-size: 14px;
}

</style>