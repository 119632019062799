<template>
    <v-card color="bgMinsal" elevation="0">
      <v-card-title >
        <span class="text-center">Listado de procesos de compra</span>
      </v-card-title>
      <app-tab-component :items="tabs" centered v-if="tabs.length > 0">
        <template v-slot:[`contenido`]="{ item }">
          <component :is="item.component"></component>
        </template>
      </app-tab-component>
    </v-card>
</template>
<script>
import ConvocatoriaCatalogoElectronicoComponent
  from "@/views/PaacProveedor/components/ConvocatoriaCatalogoElectronicoComponent.vue";
import PaacTabsComponent from "@/views/PaacProveedor/components/PaacTabsComponent.vue";
import ErrorPaacProveedor from "@/views/PaacProveedor/ErrorPaacProveedor.vue";

export default {
  name: "paacProveedor",
  components: {
    ErrorPaacProveedor,

  },
  data: () => ({
    // refactorizacion
    tabs: [],
    perfilCompleto: null,
    tieneSancion: null,
  }),
  methods: {
    async getProfilePercentage() {
      let response = await this.services.Proveedores.getProviderPercentage();

      if (response.status == 200) {
        this.perfilCompleto = response?.data.perfil_completo;
        this.tieneSancion = response?.data.tieneSancionHomologada;
        this.llenarTabs();
      }
    },
    llenarTabs() {
      this.tabs = [
        {
          nombre: 'Procesos de compra',
          component: PaacTabsComponent,
          hide_item: !(this.perfilCompleto === true && !this.tieneSancion),
        },
        {
          nombre: 'Convocatorias de catálogo electrónico',
          component: ConvocatoriaCatalogoElectronicoComponent,
          hide_item: !(this.perfilCompleto === true && !this.tieneSancion),
        },
        {
          nombre: 'Error',
          component: ErrorPaacProveedor,
          hide_item: !!(this.perfilCompleto === true && !this.tieneSancion),
        }

      ]
    }
  },
  created() {
    this.getProfilePercentage();
  }
};
</script>
