<script>
import ProgresiveLoadAutocompleteOld from "@/components/utils/ProgresiveLoadAutocompleteOld.vue";
import FiltrosSticky from "@/views/CmReporteria/components/FiltrosSticky.vue";
import ConfirmationModalComponent from "@/components/utils/ConfirmationModalComponent.vue";
import {
  createLoadable,
  isResponseSuccesful,
  setLoadableResponse,
  toggleLoadable,
  togglePageable
} from "@/utils/loadable";
import PdfModal from "@/components/PdfModal.vue";
import AdjuntoConvocatoria from "@/views/CmConvocatorias/components/AdjuntoConvocatoria.vue";

export default {
  name: "ConvocatoriaCatalogoElectronicoComponent",
  components: {AdjuntoConvocatoria, PdfModal, ConfirmationModalComponent, FiltrosSticky, ProgresiveLoadAutocompleteOld},
  data: () => ({
    listado_convocatorias: [],
    filtros: {
      busqueda: null,
      participacion: false,
    },
    paginacion: {
      page: 1,
      per_page: 12,
      total_rows: 0,
    },
    // Participacion nueva
    convocatoriaActiva: null,
    confirmacionParticipacionAbierta: false,
    // Loading
    cargando: {
      participacion: false,
    },
    participacion: createLoadable(null),
    modalVisualizacionAdjuntoConvocatoriaAbierta: false,
    visualizacionAdjuntoConvocatoria: createLoadable(null),
    modalEdicionAdjuntoAbierta: false,
    edicionAdjuntoInfo: null,
    confirmacionEdicionAdjuntoAbierta: false,
    edicionAdjunto: createLoadable(null),
    visualizacionAdjunto: createLoadable(null),
    modalVisualizacionAdjuntoAbierta: false,

  }),
  methods: {
    async listarConvocatorias() {
      let params = {
        pagination: true,
        per_page: this.paginacion.per_page,
        page: this.paginacion.page,
        participando: this.filtros.participacion,
        nombre: this.filtros.busqueda
      }
      const { data: { data: listados} } = await this.services.ConvocatoriaServices.cargarConvocatoriasPorParticipacion(params)
      this.listado_convocatorias = listados;
    },
    limpiar() {
      this.filtros.busqueda = null;
      this.filtros.participacion = false;
      this.listarConvocatorias();
    },
    iconoEstado({estado: {codigo}}) {
      switch (codigo) {
        case 'RECEPCION':
          return 'mdi-email-arrow-left';
        case 'EVALUACION':
          return 'mdi-progress-pencil';
        case 'RESULTADOS':
          return 'mdi-order-bool-descending-variant';
        default:
          return 'mdi-timer-off';
      }
    },
    colorEstado({estado: {codigo}}) {
      switch (codigo) {
        case 'RECEPCION':
          return 'secondary';
        case 'EVALUACION':
          return 'warning';
        case 'RESULTADOS':
          return 'success';
        default:
          return 'blueGrayMinsal';
      }
    },
    mostrarParticipar({ participacion }) {
      return !participacion || participacion.id_estado_participacion_prov === 2;
    },
    abrirConfirmacionParticipacion(convocatoria) {
      this.convocatoriaActiva = convocatoria;
      this.confirmacionParticipacionAbierta = true;
    },
    async visualizarAdjuntoConvocatoria(convocatoria) {
      this.modalVisualizacionAdjuntoConvocatoriaAbierta = true;
      const idConvocatoria = convocatoria.id;
      toggleLoadable(this.visualizacionAdjuntoConvocatoria);
      const { data } = await this.services.ConvocatoriaServices.visualizarAdjuntoConvocatoria(idConvocatoria);
      setLoadableResponse(this.visualizacionAdjuntoConvocatoria, data, { isFile: true });
    },
    mostrarBotonAdjuntos({ participacion }) {
      return participacion && participacion.id_estado_participacion_prov === 1;
    },
    async participarEnConvocatoria() {
      const idConvocatoria = this.convocatoriaActiva.id;
      togglePageable(this.participacion);
      const { data } = await this.services.ConvocatoriaServices.participarEnConvocatoria(idConvocatoria);
      setLoadableResponse(this.participacion, data, { skipOnSuccess: true, showAlertOnSuccess: true });
      this.confirmacionParticipacionAbierta = false;

      if (!isResponseSuccesful(data)) {
        if (data.error.code === 'TIME_OUT_ETAPA_RECEPCION') this.cargarProcesos();
        return;
      }

      this.listarConvocatorias();
    },
    manejarVisibilidadModalVisualizacionAdjunto(visible) {
      this.modalVisualizacionAdjuntoAbierta = visible;
    },
    // Ofertas
    abrirModalEdicionAdjunto(convocatoria) {
      this.convocatoriaActiva = {...convocatoria};
      this.modalEdicionAdjuntoAbierta = true;
    },
    confirmarEdicionAdjunto(adjunto) {
      this.edicionAdjuntoInfo = adjunto;
      this.modalEdicionAdjuntoAbierta = false;
      this.confirmacionEdicionAdjuntoAbierta = true;
    },
    async editarAdjunto() {
      const idParticipacion = this.convocatoriaActiva.participacion.id;
      const formData = new FormData();
      formData.append('adjunto', this.edicionAdjuntoInfo);

      toggleLoadable(this.edicionAdjunto)
      const { data } = await this.services.ConvocatoriaServices.editarAdjuntoParticipacion(idParticipacion, formData);
      setLoadableResponse(this.edicionAdjunto, data, { skipOnSuccess: true, showAlertOnSuccess: true });
      this.confirmacionEdicionAdjuntoAbierta = false;

      if (!isResponseSuccesful(data)) return;
    },
    manejarVisibilidadEditarOferta(visible) {
      this.modalEdicionAdjuntoAbierta = visible;
    },
    async visualizarAdjuntoParticipacion(convocatoria) {
      this.modalVisualizacionAdjuntoAbierta = true;
      const idParticipacion = convocatoria.participacion.id;
      toggleLoadable(this.visualizacionAdjunto);
      const { data } = await this.services.ConvocatoriaServices.visualizarAdjuntoParticipacion(idParticipacion);
      setLoadableResponse(this.visualizacionAdjunto, data, { isFile: true });
    },
    manejarVisibilidadModalVisualizacionAdjuntoConvocatoria(visible) {
      this.modalVisualizacionAdjuntoConvocatoriaAbierta = visible;
    },
  },
  created() {
    this.listarConvocatorias();
  }
}
</script>

<template>
  <div>
    <div>
      <FiltrosSticky icono="mdi-filter-settings" class="mb-15">
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-text-field
                v-model="filtros.busqueda"
                outlined
                label="Buscar convenio por código/nombre"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-switch
                inset
                v-model="filtros.participacion"
                :label="filtros.participacion ? 'Participando': 'No participando'"
                :color="filtros.participacion ? 'primary': 'grey'"/>
          </v-col>
          <v-col cols="12" md="6">
            <div class="d-flex justify-center align-center flex-wrap-reverse flex-md-nowrap" style="gap: 16px;">
              <v-btn
                  color="primary"
                  class="flex-grow-1 flex-shrink-1"
                  x-large
                  @click="listarConvocatorias"
              >
                Buscar
              </v-btn>
              <v-btn
                  color="primary"
                  class="flex-grow-1 flex-shrink-1"
                  outlined
                  x-large
                  @click="limpiar"
              >
                Limpiar
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </FiltrosSticky>
      <v-row  justify="space-around" class="gap-1 mt-15 pt-10">
        <v-card
            v-for="convocatoria in listado_convocatorias"
            class="my-5"
            :key="convocatoria.id"
            hover
            width="450">
          <v-card-title class="text-break " style="font-size: 16px; height: 120px">
            {{ convocatoria.encabezado }}

          </v-card-title>
          <v-divider />
          <v-card-text style="font-size: 18px">
            <p>
              <span class="font-weight-bold">Fecha de recepción:</span>
              {{ moment(convocatoria.fecha_recepcion).format("DD/MM/YYYY") }}
            </p>
            <p>
              <span class="font-weight-bold">Fecha de evaluación:</span>
              {{ moment(convocatoria.fecha_evaluacion).format("DD/MM/YYYY") }}
            </p>
            <p>
              <span class="font-weight-bold">Fecha de resultados:</span>
              {{ moment(convocatoria.fecha_resultados).format("DD/MM/YYYY") }}
            </p>
            <p>
           <span class="font-weight-bold">
             Estado:
           </span>
              <v-chip
                  :color="colorEstado(convocatoria)"
                  class="white--text"
              >
                <v-icon
                    class="mr-1"
                    color="white"
                    style="font-size: 22px !important;">{{ iconoEstado(convocatoria) }}</v-icon>
                {{ convocatoria.estado.nombre }}
              </v-chip>
            </p>
          </v-card-text>
          <v-divider class="my-5"/>
          <v-card-actions class="mb-5">
            <v-row justify="center" class="gap-1">
              <v-tooltip
                  color="btnGreen"
                  :key="10"
                  v-if="mostrarParticipar(convocatoria) & convocatoria.estado.codigo === 'RECEPCION'"
                  top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      rounded
                      style="order: 10"
                      color="btnGreen"
                      @click.stop="abrirConfirmacionParticipacion(convocatoria)"
                  >
                    <v-icon color="white">mdi-account-check</v-icon>
                  </v-btn>
                </template>
                <span>Participar</span>
              </v-tooltip>
              <v-tooltip
                  color="publicado"
                  :key="20"
                  v-if="convocatoria.adjunto" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      rounded
                      style="order: 20"
                      color="publicado"
                      @click.stop="visualizarAdjuntoConvocatoria(convocatoria)"
                  >
                    <v-icon color="white" >mdi-file-pdf-box</v-icon>
                  </v-btn>
                </template>
                <span>Ver documento convocatoria</span>
              </v-tooltip>
              <template v-if="mostrarBotonAdjuntos(convocatoria)">
                <v-tooltip :key="30" color="btnGreen" v-if="convocatoria.estado.codigo === 'RECEPCION'" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        style="order: 30"
                        color="btnGreen"
                        rounded
                        @click.stop="abrirModalEdicionAdjunto(convocatoria)"
                    >
                      <v-icon color="white">mdi-offer</v-icon>
                    </v-btn>
                  </template>
                  <span>Subir oferta</span>
                </v-tooltip>
                <v-tooltip :key="40" v-if="convocatoria.participacion?.adjunto" top color="#8e44ad">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        style="order: 40"
                        color="#8e44ad"
                        rounded
                        @click.stop="visualizarAdjuntoParticipacion(convocatoria)"
                    >
                      <v-icon color="white">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Ver oferta</span>
                </v-tooltip>

                <v-tooltip :key="50" color="blueGrayMinsal" v-if="convocatoria.participacion && convocatoria.participacion.id_estado_participacion_prov !== 2" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        style="order: 50"
                        color="blueGrayMinsal"
                        rounded
                        @click.stop="$router.push(`/cm-documentos-convocatoria/${convocatoria.id}`)"
                    >
                      <v-icon color="white">mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span class="d-inline-block text-center">Ver documentos de la convocatoria</span>
                </v-tooltip>
              </template>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-row>
    </div>
    <!-- Confirmacion participacion -->
    <ConfirmationModalComponent
        :is-open="confirmacionParticipacionAbierta"
        description="¿Desea participar en esta convocatoria?"
        :isLoading="cargando.participacion"
        @confirm="participarEnConvocatoria"
        @cancel="confirmacionParticipacionAbierta = false"
    />
    <PdfModal
        :is-open.sync="modalVisualizacionAdjuntoConvocatoriaAbierta"
        :source-loadable="visualizacionAdjuntoConvocatoria"
        filename="adjunto-convocatoria"
    />
    <AdjuntoConvocatoria
        :is-open="modalEdicionAdjuntoAbierta"
        @on-save="confirmarEdicionAdjunto"
        @on-visibility-change="manejarVisibilidadEditarOferta"
    />
    <ConfirmationModalComponent
        :is-open="confirmacionEdicionAdjuntoAbierta"
        description="¿Desea actualizar el adjunto?"
        :is-loading="edicionAdjunto.isLoading"
        @confirm="editarAdjunto"
        @cancel="confirmacionEdicionAdjuntoAbierta = false"
    />
    <!-- Visualizacion archivos -->
    <PdfModal
        :isOpen="modalVisualizacionAdjuntoAbierta"
        :source-loadable="visualizacionAdjunto"
        @on-visibility-change="manejarVisibilidadModalVisualizacionAdjunto"
        filename="adjunto-participacion"
    />
  </div>
</template>

<style scoped>

</style>