<template>
    <v-expansion-panels v-model="panel" :class="stickyClass">
        <v-expansion-panel key="1">
            <v-expansion-panel-header class="justify-space-between flex-wrap" style="gap: 16px;">
                <div class="d-flex align-center" style="gap: 8px;">
                    <v-icon>{{ icono }}</v-icon>
                    <span class="text-subtitle-1 font-weight-bold text-uppercase">{{ titulo }}</span>
                </div>
                <slot name="header-append"></slot>
            </v-expansion-panel-header>
            <v-divider />
            <v-expansion-panel-content>
                <div class="px-4 py-4">
                    <slot></slot>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>
<script>
export default {
    name: 'FiltrosSticky',
    props: {
        icono: { type: String, required: true },
        titulo: { type: String, default: 'Filtros' },
        modoSticky: { type: Boolean, default: true }, 
    },
    data: () => ({
        panel: 0,
    }),
    computed: {
        stickyClass() {
            let classname = 'filtros-sticky';

            if (this.modoSticky) {
                classname+= ' sticky__active'
            }

            return classname;
        },
    },
}
</script>
<style scoped>
:deep(.v-expansion-panel-content__wrap) {
    padding: 0px !important;
}

.filtros-sticky.sticky__active.v-item-group {
    position: sticky;
    top: 0;
    z-index: 150;
}

@media (min-width: 960px) {
    .filtros-sticky.sticky__active.v-item-group  {
        top: 64px;
    }
}
</style>