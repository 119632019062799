<template>
  <v-card color="bgMinsal" class="mb-5 pb-5" elevation="0">
    <v-card-text>
      <FiltrosSticky icono="mdi-filter-settings" class="mb-10">
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-text-field
                v-model="filtros.busqueda"
                outlined
                label="Buscar procesoo por código/nombre"
            />
          </v-col>
          <v-col cols="12" md="6">
            <ProgresiveLoadAutocompleteOld
                v-model="filtros.institucion"
                class="flex-grow-1 flex-shrink-1"
                style="min-width: 200px"
                label="Institución"
                placeholder="Seleccione una institución"
                itemText="nombre"
                itemValue="id"
                hide-details
                clearable
                :service="servicio"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-switch
                inset
                v-model="filtros.participacion"
                :label="filtros.participacion ? 'Participando': 'No participando'"
                :color="filtros.participacion ? 'primary': 'grey'"/>
          </v-col>
          <v-col cols="12" md="2">
            <v-switch
                inset
                v-model="filtros.recomendado"
                :label="filtros.recomendado ? 'Recomendados': 'No recomendados'"
                :color="filtros.recomendado ? 'primary': 'grey'"/>
          </v-col>
          <v-col cols="12" md="4">
            <ProgresiveLoadAutocompleteOld
                v-model="filtros.tipo_actividad"
                class="flex-grow-1 flex-shrink-1"
                style="min-width: 200px"
                label="Tipo Actividad"
                placeholder="Seleccione una institución"
                itemText="nombre"
                itemValue="id"
                hide-details
                clearable
                :service="servicio2"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
                v-model="filtros.tipos_contrato"
                outlined
                hide-details
                clearable
                :items="switchs.tipo_contrato"
                item-value="id"
                item-text="nombre"
                label="Método de contratación"
                placeholder="Seleccione un método de contratación"
            />
          </v-col>

          <v-col cols="12" md="6">
            <div class="d-flex justify-center align-center flex-wrap-reverse flex-md-nowrap" style="gap: 16px;">
              <v-btn
                  color="primary"
                  class="flex-grow-1 flex-shrink-1"
                  x-large
                  @click="listarProcesoCompra"
              >
                Buscar
              </v-btn>
              <v-btn
                  color="primary"
                  class="flex-grow-1 flex-shrink-1"
                  outlined
                  x-large
                  @click="limpiar"
              >
                Limpiar
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </FiltrosSticky>
      <v-row
          justify="space-around"
          class="gap-1 mt-15 mb-5"
          v-if="!cargando.listado_proceso_compra"
      >
        <tarjetasProcesos
            v-for="proceso_compra in listado_procesos_compra"
            :key="proceso_compra.id"
            @update:redirect="irA"
            :proceso="proceso_compra"
            @mostrarActividades="mostrarActividades"
            @elementoVacio="elementoVacio"
        />

      </v-row>
      <v-row
          v-else
          justify="space-around"
          class="gap-1 mt-10 mb-5 flex-wrap">
        <div v-for="n in 12">

          <v-skeleton-loader

              class="mx-auto"
              width="300"
              type="card"
          ></v-skeleton-loader>
        </div>
      </v-row>
      <v-row
          justify="center"
          v-if="!cargando.listado_proceso_compra">
        <v-pagination
            v-model="pagination.page"
            :length="Math.ceil(pagination.total_rows / pagination.per_page)"
            :total-visible="10"
            @input="paginar"
        ></v-pagination>
      </v-row>
    </v-card-text>
    <v-divider/>
    <app-dialog
        :max-width="700"
        v-model="mostrar_actividad_economica"
        :showClose="false"
        title="Listado de Actividades Económicas">
      <template v-slot:content>
        <v-row>
          <v-col cols="12">
            <ul>
              <li v-for="actividad in actividad_temporal" :key="actividad.id" class="my-1">
                <span v-if="actividad.a.codigo"> {{ actividad.a.codigo }} - </span>
                <span>{{ actividad.a.nombre }} - </span>
                <v-chip :color="actividad.a.ae.color" > {{ actividad.a.ae.nombre}}</v-chip>
              </li>
            </ul>
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <!-- <v-btn color="secondary" outlined  @click="showModal = false" class="mr-5">Cancelar</v-btn> -->
        <v-btn color="red" outlined @click="mostrar_actividad_economica = false">Cerrar</v-btn>
      </template>
    </app-dialog>
  </v-card>
</template>
<script>
import FiltrosSticky from "@/views/CmReporteria/components/FiltrosSticky.vue"
import ProgresiveLoadAutocompleteOld from "@/components/utils/ProgresiveLoadAutocompleteOld.vue"
import TarjetasProcesos from "@/views/PaacProveedor/components/TarjetasProcesos.vue"
import UsuariosServices from "@/services/Usuarios.services";
import ActividadesServices from "@/services/Actividad.services";

const serv = UsuariosServices.getInstitucionesFiltradas;
const serv2 = ActividadesServices.getActividad;
export default {
  name: 'PaacTabsComponent',
  components: {FiltrosSticky, ProgresiveLoadAutocompleteOld, TarjetasProcesos},
  props: {
  },
  data: () => ({
    actividad_temporal: [],
    mostrar_actividad_economica: false,
    listado_procesos_compra: [],
    servicio: serv,
    servicio2: serv2,
    filtros: {
      tipo_actividad: null,
      busqueda: null,
      tipos_contrato: null,
      participacion: false,
      institucion: null,
      recomendado: false,
    },
    pagination: {
      page: 1,
      per_page: 12,
      total_rows: 0,
    },
    cargando: {
      listado_proceso_compra: false
    },
    switchs: {
      tipo_contrato: []
    },
  }),
  methods: {
    async llenarSelects() {
      const { data: tipo_contratos } = await this.services.ProveedoresServices.getTipoContrato();
      this.switchs.tipo_contrato = tipo_contratos;
    },
    elementoVacio() {
      this.temporalAlert({
        message: "No tiene actividades económicas",
        show: true,
        type: "warning",
      });
    },
    irA({ id: id_proceso}){
      window.open(`/pac-procesos/etapas/${id_proceso}`, '_blank');
    },
    limpiar(){
      this.filtros.busqueda = null
      this.filtros.participacion = false
      this.filtros.tipos_contrato = null
      this.filtros.tipo_actividad = null
      this.filtros.institucion = null
      this.filtros.recomendado = false
      this.listarProcesoCompra();
    },
    mostrarActividades(actividades) {
      this.mostrar_actividad_economica = true;
      this.actividad_temporal = actividades;
    },

    async listarProcesoCompra() {
      let props = {
        page: this.pagination.page,
        busqueda: this.filtros.busqueda,
        participacion: this.filtros.participacion,
        id_modalidad: this.filtros.tipos_contrato,
        id_institucion: this.filtros.institucion,
        tipo_actividad: this.filtros.tipo_actividad,
        recomendados: this.filtros.recomendado,
      }
      this.cargando.listado_proceso_compra = true;
      const { data, headers } = await this.services.Proveedores.getProcesosProveedores(props);
      this.cargando.listado_proceso_compra = false;
      this.listado_procesos_compra = data;
      this.pagination.total_rows = headers.total_rows
    },

    paginar(page){
      this.pagination.page = page;
      this.listarProcesoCompra();
    },
  },
  created() {
    this.listarProcesoCompra();
    this.llenarSelects();
  }
}
</script>
<style lang="scss" scoped>

p {
  font-size: 14px;
}

</style>