<template>
 <div>
   <v-row justify="center" class="mb-8">
     <v-col cols="12" sm="6" md="4" align="center">
       <v-img src="../../assets/img/comprasal_login.png"/>
     </v-col>
   </v-row>
   <h3 class="text-center">
     {{ mensajeError() }}
   </h3>
 </div>
</template>
<script>
export default {
  name: 'ErrorPaacProveedor',
  data: () => ({
    perfilCompleto: null,
    tieneSancion: null,
  }),
  methods: {
    async getProfilePercentage() {
      let response = await this.services.Proveedores.getProviderPercentage();

      if (response.status == 200) {
        this.perfilCompleto = response?.data.perfil_completo;
        this.tieneSancion = response?.data.tieneSancionHomologada;
        this.llenarTabs();
      }
    },
    mensajeError() {
      let mensaje = null

      if (!this.perfilCompleto && this.tieneSancion) {
        mensaje = 'No puede visualizar los procesos vigentes al no tener perfil completo ó al poseer sanciones'
      } else if (!this.perfilCompleto) {
        mensaje = 'No puede visualizar los procesos vigentes al no tener perfil completo'
      }else if (this.tieneSancion) {
        mensaje = 'No puede visualizar los procesos vigentes al poseer sanciones'
      }
      return mensaje
    }
  },
  computed() {

  },
  created() {
    this.getProfilePercentage();
  }
}
</script>