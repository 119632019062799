<template>
  <v-autocomplete
    outlined
    v-bind="mergedProps"
    v-on="$listeners"
    v-model="model"
    :loading="isLoading"
    :items="elementos"
    :return-object="return_object"
    no-filter
    clearable
    @keydown="handleChangeDebounced"
  >
    <template v-slot:no-data>
      <div class="text-center py-2">
        <p v-if="!isLoading" class="mx-0 my-0">No hay datos disponibles</p>
        <v-progress-circular
          v-else
          indeterminate
          color="primary"
          :size="25"
          :width="3"
        />
      </div>
    </template>
    <template v-slot:append-item>
      <div v-intersect="debouncedIntersect" class="text-center">
        <v-progress-circular
          v-if="elementos.length > 0 && isLoading"
          indeterminate
          class="mt-2"
          color="primary"
          :size="25"
          :width="3"
        />
      </div>
    </template>
  </v-autocomplete>
</template>
<script>
import { debounce, omit } from "lodash";

export default {
  name: "ProgresiveLoadAutocomplete",
  props: {
    label: { type: String },
    itemText: { type: String },
    itemValue: { type: String },
    service: { type: Function },
    id_prov: { type: Number, default: null },
    return_object: { type: Boolean, default: false },
  },
  data: () => ({
    term: "",
    model: null,
    isLoading: false,
    paginacion: {
      per_page: 30,
      page: 1,
    },
    elementos: [],
  }),
  methods: {
    async handleChange(evt) {
      this.paginacion = {
        per_page: 30,
        page: 1,
      };
      this.term = evt.target.value;
      if (this.term === "") {
        return (this.elementos = []);
      }
      await this.cargarElementos();
    },
    async handleIntersect(entries, observer, isIntersecting) {
      if (!isIntersecting) return;
      this.paginacion.page = this.paginacion.page + 1;
      await this.cargarElementos(false);
    },
    async cargarElementos(borrar = true) {
      this.isLoading = true;
      const { data } = await this.service({
        ...this.paginacion,
        busqueda: this.term,
        proveedor: this.id_prov
      });
      this.isLoading = false;
      if (borrar && data.length > 0) {
        return (this.elementos = data);
      }
      if (data.length === 0) {
        return (this.paginacion.page = this.paginacion.page - 1);
      }
      this.elementos.push(...data);
    },
    async cargarMasElementos() {},
  },
  watch: {
    value(val) {
      this.model = val;
    },
  },
  computed: {
    mergedProps() {
      return { ...this.$props, ...omit(this.$attrs, "value") };
    },
    value() {
      return this.$attrs.value;
    },
  },
  created() {
    this.handleChangeDebounced = debounce(this.handleChange, 1000);
    this.debouncedIntersect = debounce(this.handleIntersect, 1000);
  },
};
</script>
